<template>
    <div class="product-card prd-card">
        <div class="product-card-top">
            <a :href="productUrl" class="product-image">
                <img
                    :src="baseImage"
                    :class="{ 'image-placeholder': !hasBaseImage }"
                    :alt="product.name"
                />
            </a>

            <div class="product-card-actions">
                <button
                    class="btn btn-wishlist"
                    :class="{ added: inWishlist }"
                    :title="$trans('storefront::product_card.wishlist')"
                    @click="syncWishlist"
                >
                    <i class="la-heart" :class="inWishlist ? 'las' : 'lar'"></i>
                </button>

                <!-- <button
                    class="btn btn-compare"
                    :class="{ added: inCompareList }"
                    :title="$trans('storefront::product_card.compare')"
                    @click="syncCompareList"
                >
                    <i class="las la-random"></i>
                </button> -->
            </div>

            <ul class="list-inline product-badge">
                <li class="badge badge-danger" v-if="item.is_out_of_stock">
                    {{ $trans("storefront::product_card.out_of_stock") }}
                </li>

                <li class="badge badge-primary" v-else-if="product.is_new">
                    {{ $trans("storefront::product_card.new") }}
                </li>

                <li
                    class="badge badge-success"
                    v-if="item.has_percentage_special_price"
                >
                    -{{ item.special_price_percent }}%
                </li>
            </ul>
        </div>

        <div class="product-card-middle">
            <product-rating
                :ratingPercent="product.rating_percent"
                :reviewCount="product.reviews.length"
            >
            </product-rating>

            <a :href="productUrl" class="product-name">
                <h6>{{ product.name }}</h6>
            </a>

            <div
                class="product-price product-price-clone"
                v-html="item.formatted_price"
            ></div>
        </div>

        <div
            class="product-card-bottom"
            :class="{ 'center-buttom-card': cartItem?.id }"
        >
            <!-- <div class="product-price" v-html="item.formatted_price"></div> -->

            <div class="number-picker" v-if="cartItem?.id">
                <div class="input-group-quantity">
                   <!-- Button for incrementing quantity -->
                   <button
                        type="button"
                        class="btn btn-number btn-plus"
                        :disabled="isQtyIncreaseDisabled(cartItem)"
                        @click="updateQuantity(cartItem.qty + 1)"
                    >
                        +
                    </button>
                    <span v-if="addingToCart" class="btn-loading"></span>

                    <!-- Input for quantity -->
                    <input
                        v-else
                        type="text"
                        :value="cartItem.qty"
                        min="1"
                        :max="maxQuantity(cartItem)"
                        class="form-control input-number input-quantity"
                        @focus="$event.target.select()"
                        @input="changeQuantity(Number($event.target.value))"
                        @keydown.up="updateQuantity(cartItem.qty + 1)"
                        @keydown.down="updateQuantity(cartItem.qty - 1)"
                    />
                    <!-- Button for decrementing quantity -->
                    <button
                        type="button"
                        class="btn btn-number btn-minus"
                        @click="updateQuantity(cartItem.qty - 1)"
                    >
                        -
                    </button>
                  
                </div>
            </div>
            <div v-else class="card-buttons-parent">
                <button
                    v-if="hasNoOption || item.is_out_of_stock"
                    class="btn btn-primary btn-add-to-cart"
                    :disabled="item.is_out_of_stock"
                    @click="add"
                >
                    <i class="las la-cart-arrow-down"></i>
                    <span v-if="addingToCart" class="btn-loading"></span>
                    <span v-else>
                    {{ $trans("storefront::product_card.add_to_cart") }}
                    </span>
                </button>

                <a
                    v-else
                    :href="productUrl"
                    class="btn btn-primary btn-add-to-cart"
                >
                    <i class="las la-eye"></i>
                    {{ $trans("storefront::product_card.view_options") }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";
import ProductCardMixin from "../mixins/ProductCardMixin";

export default {
    mixins: [ProductCardMixin],

    props: ["product"],

    computed: {
        item() {
            return {
                ...(this.product.variant ? this.product.variant : this.product),
            };
        },
    },
    data() {
        return {
            cartItem: false,
            cart: store.state.cart,
        };
    },
    created() {
        this.unwatchCart = this.$watch(
            () => store.state.cart,
            (newCart, oldCart) => {
                this.cart = newCart;
                this.cartItem = store.getProductFromCart(this.product.id);
            },
            { deep: true } // Enable deep watching for nested properties
        );
    },
    destroyed() {
        // Clean up the watcher to prevent memory leaks
        if (this.unwatchCart) {
            this.unwatchCart();
        }
    },
    methods: {
        async add() {
            await this.addToCart();
            this.cartItem = store.getProductFromCart();
        },
        updateQuantity(qty) {
            if (qty < 1) {
                this.removeCartItem();
                return;
            } else if (
                this.product.max_qty_per_order &&
                qty > this.product.max_qty_per_order
            ) {
                qty = this.product.max_qty_per_order;
                // notify user
                this.$notify("You can't add more than " + qty + " items");
            }

            axios
                .put(
                    route("cart.items.update", {
                        id: this.cartItem.id,
                        ...(store.hasCoupon() && {
                            coupon_code: store.getCoupon(),
                        }),
                    }),
                    {
                        qty: qty,
                    }
                )
                .then((response) => {
                    store.updateCart(response.data);
                    this.cartItem = response.data.items[this.cartItem.id];
                })
                .catch(({ response }) => {
                    store.updateCart(response.data.cart);

                    this.$notify(response.data.message);
                });
        },

        maxQuantity({ item }) {
            return item.is_in_stock && item.does_manage_stock ? item.qty : null;
        },
        isQtyIncreaseDisabled(cartItem) {
            return (
                this.maxQuantity(cartItem) !== null &&
                cartItem.qty >= cartItem.item.qty
            );
        },

        changeQuantity(qty) {
            if (isNaN(qty)) {
                qty = 1;

                store.updateCart(qty);

                return;
            } else if (qty < 1) {
                qty = 1;

                this.removeCartItem();

                return;
            } else if (qty > this.product.max_qty_per_order) {
                qty = this.product.max_qty_per_order;

                store.updateCart(qty);

                return;
            }

            this.cartItem.qty = qty;

            if (this.exceedsMaxStock(this.cartItem)) {
                qty = this.cartItem.item.qty;

                store.updateCart(qty);

                return;
            }

            store.updateCart(qty);
        },

        removeCartItem() {
            store.removeCartItem(this.cartItem);

            axios
                .delete(
                    route("cart.items.destroy", {
                        id: this.cartItem.id,
                        ...(store.hasCoupon() && {
                            coupon_code: store.getCoupon(),
                        }),
                    })
                )
                .then((response) => {
                    store.updateCart(response.data);
                });

            this.cartItem = false;
        },
    },
    watch: {
        "cartItem.qty": {
            handler: function (newCart, oldCart) {
                this.cartItem = store.getProductFromCart(this.product.id);
            },
            deep: true,
        },
        "store.state.cart.items": {
            handler: function (newItems, oldItems) {
                const productId = this.product.id;
                this.cartItem = store.getProductFromCart(productId);
            },
            deep: true,
        },
    },
    mounted() {
        this.cartItem = store.getProductFromCart(this.product.id); // Initialize cartItem when component is mounted
    },
};
</script>
